import { createRouter, createWebHistory } from "vue-router";
import SoccerMainVue from "../views/SoccerMain.vue";
import BasketBallMail from "../views/BasketBallMain.vue";
import TennisMain from "../views/TennisMain.vue";
import VolleyBallMain from "../views/VolleyBallMain.vue";

const router = createRouter({
  history: createWebHistory(),
  base: `/3d-live-animation`,
  routes: [
    // {
    //   path: "/3d-live-animation/:locale/:fixture_id",
    //   name: "Soccer",
    //   component: SoccerMainVue,
    // },
    {
      path: "/3d-live-animation/soccer/:locale/:fixture_id",
      name: "Soccer",
      component: SoccerMainVue,
    },
    {
      path: "/3d-live-animation/basketball/:locale/:fixture_id",
      name: "Basketball",
      component: BasketBallMail,
    },
    {
      path: "/3d-live-animation/tennis/:locale/:fixture_id",
      name: "Tennis",
      component: TennisMain,
    },
    {
      path: "/3d-live-animation/volleyball/:locale/:fixture_id",
      name: "Volleyball",
      component: VolleyBallMain,
    },
    // {
    //   path: "/basketball/:locale/:fixture_id",
    //   name: "Basketball1",
    //   component: BasketBallMail,
    // },
  ],
});

export default router;
